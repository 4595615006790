<template>
  <a
    v-if="link && hasUrl && label"
    :href="linkOptions?.url"
    target="_blank"
    :class="$style.root"
  >
    <slot />
  </a>

  <i18nLink
    v-else-if="link && hasPage && LinkToPageRouteObject"
    :to="LinkToPageRouteObject"
    :class="$style.root"
  >
    <slot />
  </i18nLink>

  <i18nLink
    v-else-if="link && firstLinkToCollection?.key && LinkToCollectionRouteObject "
    :to="LinkToCollectionRouteObject"
    :class="$style.root"
  >
    <slot />
  </i18nLink>
</template>

<script lang="ts" setup>
import { without } from 'lodash-es'

const props = defineProps<{
  link: object
}>()

const label = props?.link?.label

const linkOptions = without(Object.keys(props?.link), ['id', 'label'])?.sort()?.reduce((objEntries, key) => {
  objEntries[key] = props?.link[key]
  return objEntries
}, {})

const hasUrl = linkOptions?.url
const hasPage = linkOptions?.page
const LinkToPageRouteObject = baseRouteGetObject(
  strapiGetRouteReference(linkOptions?.page)?.name
)

const firstLinkToCollection = baseFindValAndKeyByKeyInObject(linkOptions, 'data')
const LinkToCollectionRouteObject = baseRouteGetObject(
  strapiGetRouteReference(firstLinkToCollection?.key)?.name,
  baseStrapiGetAttributes(firstLinkToCollection)?.slug
)

const attributes = baseStrapiGetAttributes

</script>

<style module>
.root {
  composes: reset-link from global;
}
</style>
